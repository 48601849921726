import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import DocumentiUtili from '../components/documenti-utili'
import PhotoGallery from '../components/photogallery'
import Video from '../components/video'

import utils from '../utility/utils.js'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

class AvvisiTemplate extends React.Component {
  render() {
    const data = this.props.data.contentfulAvvisi
    const breadcrumb = [{ title: 'Avvisi' }, { title: `${data.titolo}` }]

    return (
      <Layout className="container" location={this.props.location}>
        <div className="container">
          <Helmet title={data.titolo} />
          <Breadcrumbs breadcrumb={breadcrumb} />
          <div className="row">
            <div className="col-md-8">
              <article className="news news--detail">
                <h1>{data.titolo}</h1>
                <div>
                  <span className="news__date">
                    {utils.formatDate(data.createdAt)}
                  </span>
                  {!!data.descrizione && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.descrizione.childMarkdownRemark.html,
                      }}
                    />
                  )}

                  <div className="clearfix" />
                </div>
                {!!data.fotogallery && (
                  <div>
                    <h2 className="servizi__subject">Fotogallery</h2>
                    <PhotoGallery data={data.fotogallery} />
                  </div>
                )}
                {!!data.video && (
                  <div>
                    <h2 className="servizi__subject">Video</h2>
                    <Video data={data.video} />
                  </div>
                )}
              </article>
            </div>
            <div className="col-md-4">
              {!!data.documenti && <DocumentiUtili data={data.documenti} />}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AvvisiTemplate

export const pageQuery = graphql`
  query AvvisiDetailQuery($contentful_id: String!) {
    contentfulAvvisi(contentful_id: { eq: $contentful_id }) {
      id
      titolo
      createdAt
      friendlyUrl
      descrizione {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
